<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div v-if="!item.id" class="p-dialog-title">Adding a new link</div>
      <div v-else class="p-dialog-title">Link editing</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="link_name" v-model.trim="linkData.name" autocomplete="off"/>
          <label for="link_name">Name</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="link_url" v-model.trim="linkData.url" autocomplete="off"/>
          <label for="link_url">Url</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="link_username" v-model.trim="linkData.username" autocomplete="off"/>
          <label for="link_username">Username</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="link_password" v-model.trim="linkData.password" autocomplete="off"/>
          <label for="link_password">Password</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-mb-5">
        <div class="p-float-label">
          <MultiSelect id="link_users"
                       @change="changeSelectedUsers"
                       @show="toogleDropdownOverlayVisibility(true)"
                       @hide="toogleDropdownOverlayVisibility(false)"
                       v-model="selectedUsers"
                       :options="usersList"
                       optionLabel="full_name"
                       :filter="true"
                       display="chip"/>
          <label for="link_users">Users</label>
        </div>
      </div>
      <div class="p-field p-col-12">
        <div class="p-float-label p-input-icon-right">
          <i class="ti-comment-alt" />
          <Textarea id="link_comment" v-model="linkData.comment" rows="3" :autoResize="true" autocomplete="off"/>
          <label for="link_comment">{{ $t('Comment') }}</label>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";
import settings from "@/settings";

export default {
  mixins: [ overlayVisibilityMixins ],
  emits: ['close', 'update-items'],
  name: 'TaxModal',
  props: {
    item: Object,
    visible: Boolean,
    linkUsers: Array
  },
  data() {
    return {
      dataIsSending: false,
      disableSaveButton: false,
      selectedUsers: [],
      usersList: [],
      updateUsers: false,
      removeAllUsers: false,
      linkData: {},
      submitted: false,
      isDefault: false
    }
  },
  watch: {
    item(value) {
      this.linkData = { ...value }
      this.usersList = [...this.linkUsers]
      if (value.users?.length) {
        const sortedUsers = value.users.sort((a, b) => a.id - b.id)
        this.selectedUsers = sortedUsers.map(user => {
          const userInUsersListArray = this.usersList.find(u => u.id === user.userData.id)
          if (!userInUsersListArray) {
            this.usersList.push({id: user.userData.id, full_name: (user.userData.first_name || '') + ' ' + (user.userData.last_name || '')})
          }
          return {id: user.userData?.id, full_name: (user.userData?.first_name || '') + ' ' + (user.userData.last_name || '')}
        })
      }
    },
    visible() {
      if (!this.visible) {
        this.disableSaveButton = false
        this.dataIsSending = false
        this.submitted = false
        this.selectedUsers = []
        this.usersList = []
      }
    },
    linkUsers(value) {
      this.usersData = [...value]
    },
  },
  methods: {
    changeSelectedUsers() {
      this.updateUsers = true
    },
    async saveItem() {
      this.submitted = true
      // if (!this.linkData.value) {
      //   return false
      // }
      this.disableSaveButton = true
      this.dataIsSending = true

      const link = {
        name: this.linkData.name ?? null,
        url: this.linkData.url ?? null,
        username: this.linkData.username ?? null,
        password: this.linkData.password ?? null,
        comment: this.linkData.comment ?? null,
      }

      if (this.updateUsers) {
        link.usersIds = this.selectedUsers.length ? this.selectedUsers.map(user => user.id) : null
      } else {
        link.usersIds = null
      }

      if (this.updateUsers && !this.selectedUsers.length) {
        link.removeAllUsers = true
        link.usersIds = null
      }

      if (this.linkData.id) {
        link.id = this.linkData.id
        try {
          const { status } = await httpClient.post(`link/update-link`, link)
          if (status === 200) {
            this.$emit('update-items', this.linkData.id)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`link/create-link`, link)
          if (status === 201 && data) {
            this.linkData.id = data
            link.id = data
            this.$emit('update-items', this.linkData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: settings.toastLife});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>